//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-376:_4402,_2480,_3600,_9872,_5136,_2296,_4792,_4888;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-376')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-376', "_4402,_2480,_3600,_9872,_5136,_2296,_4792,_4888");
        }
      }catch (ex) {
        console.error(ex);
      }